import React, { Component, useState, useEffect } from "react";
import { Button } from "@amzn/awsui-components-react/polaris";
import { newClient } from "@amzn/midway-identity-java-script-client";
import { CoralConfigSchema } from "@amzn/coral-client-config-types";
import { AxiosInstance } from "axios";
import "./App.css";

const badgePhotoUrl = (userId: string) =>
  `https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${userId}`;

const Content = () => {
  const [client, setClient] = useState<AxiosInstance | undefined>(undefined);
  const [userId, setUserId] = useState("nobody");
  const [imgUrl, setImgUrl] = useState(badgePhotoUrl("nobody"));

  if (!client) {
    // settings.json file can be overwritten during deployment to point to the right configuration.
    fetch("settings.json").then((data) => data.json()).then(settings => {

      const coralClientConfig: CoralConfigSchema = {
        httpEndpoint: {
          url: settings.apiEndpoint
        },
        httpSigning: {
          scheme: 'aws4-hmac-sha256',
          region: settings.region,
          service: 'execute-api'
        },
        midwayIdentity: {
          cognitoIdentityPoolId: settings.cognitoPoolId
        }
      };

      setClient(() => {
        //return null;
        return newClient(coralClientConfig) as AxiosInstance;
      });
    });
  }

  const callGetIdentity = event => {
    setUserId("nobody");
    client?.get("/identity").then((rawResponse: any) => {
      setUserId(rawResponse.data);
    });
  };

  useEffect(() => {
    setImgUrl(badgePhotoUrl(userId));
  }, [userId]);

  return (
    <div className="App">
      <header className="App-header">
        <h3>Yey! Npm-pretty-much, Polaris, React and MidwayIdentity are working as one!</h3>
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        {userId && <img src={imgUrl} alt="badge" />}
        <p />
        <Button
          children="Click me to get your identity!"
          variant="primary"
          onClick={callGetIdentity}
          disabled={!client}
        />
        <p>{userId !== "nobody" ? `Hello ${userId}!` : "Hello!"}</p>
      </header>
    </div>
  );
};

class App extends Component {
  render() {
    return (
      <div className="awsui">
        <Content />
      </div>
    );
  }
}

export default App;
